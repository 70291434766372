import KEY from '../../../../../const/storeKey'
const { ACTIONS, _POST_DETAIL } = KEY
const ACTION = ACTIONS._POST_DETAIL

const MAX_CACHE_CONTENT = 20

export default {
    KEY_NAME: _POST_DETAIL,
    ACTION,
    namespaced: true,
    state: {
        currentIdPost: '',
        cacheContent: []
    },
    getters: {
        currentIdPost: state => state.currentIdPost,
        cacheContent: state => state.cacheContent,
    },
    mutations: {
        CHANGE_ID_POST(state, _id) {
            state.currentIdPost = _id;
        },
        CHANGE_CACHE_CONTENT(state, post) {
            if(state.cacheContent < MAX_CACHE_CONTENT) state.cacheContent = [...state.cacheContent, post]
        }
    },
    actions: {
        CHANGE_ID_POST({ commit }, _id) {
            commit(ACTION.CHANGE_ID_POST, _id);
        },
        CHANGE_CACHE_CONTENT({ commit }, post) {
            commit(ACTION.CHANGE_CACHE_CONTENT, post);
        }
    }
}
